import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "d-flex flex-wrap py-5" }
const _hoisted_12 = { class: "flex-equal me-5" }
const _hoisted_13 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_14 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_15 = { class: "text-gray-800 min-w-200px" }
const _hoisted_16 = { class: "text-gray-400" }
const _hoisted_17 = { class: "text-gray-800" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "flex-equal me-5" }
const _hoisted_20 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_21 = { class: "text-gray-400" }
const _hoisted_22 = { class: "text-gray-800" }
const _hoisted_23 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_24 = { class: "text-gray-800 min-w-200px" }
const _hoisted_25 = { class: "card-body p-9" }
const _hoisted_26 = { class: "mb-10" }
const _hoisted_27 = { class: "mb-4" }
const _hoisted_28 = { class: "d-flex flex-wrap py-5" }
const _hoisted_29 = { class: "flex-equal me-5" }
const _hoisted_30 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_31 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_32 = { class: "text-gray-800 min-w-200px" }
const _hoisted_33 = { class: "text-gray-400" }
const _hoisted_34 = { class: "text-gray-800" }
const _hoisted_35 = { class: "text-gray-400" }
const _hoisted_36 = { class: "text-gray-800" }
const _hoisted_37 = { class: "text-gray-400" }
const _hoisted_38 = { class: "text-gray-800" }
const _hoisted_39 = { class: "text-gray-400" }
const _hoisted_40 = { class: "text-gray-800" }
const _hoisted_41 = { class: "flex-equal me-5" }
const _hoisted_42 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_43 = { class: "text-gray-400" }
const _hoisted_44 = { class: "text-gray-800" }
const _hoisted_45 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_46 = { class: "text-gray-800 min-w-200px" }
const _hoisted_47 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_48 = { class: "text-gray-800 min-w-200px" }
const _hoisted_49 = { class: "text-gray-400" }
const _hoisted_50 = { class: "text-gray-800" }
const _hoisted_51 = { class: "text-gray-400" }
const _hoisted_52 = { class: "text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.can('read', 'loan-calculator'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.translate('loanFundsRequestOverview')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: "/manage/loan-request/loan-funds-request-listing",
                  class: "btn btn-light me-3"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('backToList')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate('basicInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("table", _hoisted_13, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.translate('fullName')) + ": ", 1),
                      _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.loansFundsRequest.name), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.translate('email')) + ":", 1),
                      _createElementVNode("td", _hoisted_17, [
                        _createElementVNode("a", {
                          href: `mailto:${_ctx.loansFundsRequest.email}`,
                          class: "text-gray-800 text-hover-primary px-0"
                        }, _toDisplayString(_ctx.loansFundsRequest.email), 9, _hoisted_18)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("table", _hoisted_20, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.translate('phone')) + ":", 1),
                      _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.loansFundsRequest.phone), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.translate('message')) + ": ", 1),
                      _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.loansFundsRequest.message), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("h5", _hoisted_27, _toDisplayString(_ctx.translate('propertyInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("table", _hoisted_30, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.translate('loan Amount')) + ": ", 1),
                      _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.loansFundsRequest.loanAmount), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.translate('propertyValue')) + ": ", 1),
                      _createElementVNode("td", _hoisted_34, _toDisplayString(_ctx.loansFundsRequest.propertyValue), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.translate('loanDuration')) + ": ", 1),
                      _createElementVNode("td", _hoisted_36, _toDisplayString(_ctx.loansFundsRequest.loanDuration), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_37, _toDisplayString(_ctx.translate('annualProfit')) + ": ", 1),
                      _createElementVNode("td", _hoisted_38, _toDisplayString(_ctx.loansFundsRequest.annualProfit), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_39, _toDisplayString(_ctx.translate('repaymentPeriod')) + ": ", 1),
                      _createElementVNode("td", _hoisted_40, _toDisplayString(_ctx.loansFundsRequest.repaymentPeriod), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("table", _hoisted_42, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_43, _toDisplayString(_ctx.translate('totalPurchasePrice')) + ": ", 1),
                      _createElementVNode("td", _hoisted_44, _toDisplayString(_ctx.loansFundsRequest.totalPurchasePrice), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_45, _toDisplayString(_ctx.translate('bankProfit')) + ": ", 1),
                      _createElementVNode("td", _hoisted_46, _toDisplayString(_ctx.loansFundsRequest.bankProfit), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_47, _toDisplayString(_ctx.translate('downPayment')) + ": ", 1),
                      _createElementVNode("td", _hoisted_48, _toDisplayString(_ctx.loansFundsRequest.downPayment), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_49, _toDisplayString(_ctx.translate('gracePeriod')) + ":", 1),
                      _createElementVNode("td", _hoisted_50, _toDisplayString(_ctx.loansFundsRequest.gracePeriod), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_51, _toDisplayString(_ctx.translate('createdAt')) + ":", 1),
                      _createElementVNode("td", _hoisted_52, _toDisplayString(_ctx.loansFundsRequest.createdAt), 1)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}