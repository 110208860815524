
  import { defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { LoanFundsRequestObject } from '@/store/modules/LoanRequests/LoanFundsRequestModule';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'loan-funds-request-overview',
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const { can } = useAbility();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('loanFundsRequestOverview'), [
          translate('loansFunds'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_LOAN_FUNDS_REQUEST,
        route.params.id
      );

      const loansFundsRequest = ref<LoanFundsRequestObject>(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        translate,
        loansFundsRequest,
        can,
      };
    },
  });
